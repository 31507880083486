var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __restKey = (key) => typeof key === "symbol" ? key : key + "";
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// stitches.config.ts
import { createStitches, defaultThemeMap } from "@stitches/react";

// design-tokens/generated/tokens.ts
var tokens_default = {
  "Color Primitives": {
    colors: {
      blue05: "#ecf3fe",
      blue10: "#e3edff",
      blue20: "#aacaff",
      blue30: "#71a8ff",
      blue40: "#3988ff",
      blue50: "#106af2",
      blue60: "#0053d2",
      blue70: "#003da6",
      blue80: "#002a79",
      blue90: "#00194d",
      amber50: "#ffbc00",
      green10: "#e3fae8",
      green20: "#aaebba",
      green30: "#71d78e",
      green40: "#3ebf65",
      green50: "#12a13d",
      green60: "#008f2b",
      green70: "#00761c",
      green80: "#005610",
      green90: "#002f07",
      neutral00: "#ffffff",
      neutral05: "#f3f7fa",
      neutral10: "#ecf1f7",
      neutral100: "#0f1317",
      neutral20: "#d7e1ed",
      neutral30: "#cad5e5",
      neutral40: "#bcc9de",
      neutral50: "#a1adc4",
      neutral60: "#7b8ba3",
      neutral70: "#505e71",
      neutral80: "#35414e",
      neutral90: "#1f272f",
      purple10: "#efe6ff",
      purple20: "#d0b8ff",
      purple30: "#b490ff",
      purple40: "#996df8",
      purple50: "#804fea",
      purple60: "#723fde",
      purple70: "#4a1aa6",
      purple80: "#330a79",
      purple90: "#1e014d",
      red10: "#ffe3e3",
      red20: "#ffaeaa",
      red30: "#fb7e76",
      red40: "#ee544a",
      red50: "#db3025",
      red60: "#be2109",
      red70: "#c7170c",
      red80: "#790000",
      red90: "#4d0000",
      yellow10: "#fff5e3",
      yellow20: "#ffdfaa",
      yellow30: "#ffc871",
      yellow40: "#ffaf39",
      yellow50: "#f0950c",
      yellow60: "#cc6e00",
      yellow70: "#994700",
      yellow80: "#662500",
      yellow90: "#330c00"
    },
    numbers: {}
  },
  "Color Semantic (System)": {
    colors: {
      accordion_icon_active: "$neutral90",
      accordion_icon_enabled: "$neutral90",
      accordion_icon_hover: "$neutral90",
      accordion_text_active: "$neutral90",
      accordion_text_enabled: "$neutral90",
      accordion_text_hover: "$neutral90",
      avatar_icon: "$neutral30",
      avatar_surface: "$neutral80",
      badge_surface_blue: "#aacaff3d",
      badge_surface_green: "#71d78e3d",
      badge_surface_neutral: "#a1adc43d",
      badge_surface_purple: "#b490ff3d",
      badge_surface_red: "#fb7e763d",
      badge_surface_yellow: "#ffc8713d",
      badge_text_blue: "$blue60",
      badge_text_green: "$green60",
      badge_text_neutral: "$neutral70",
      badge_text_purple: "$purple60",
      badge_text_red: "$red60",
      badge_text_yellow: "$yellow60",
      breadcrumb_text: "$text_secondary",
      button_danger_icon_active: "$neutral00",
      button_danger_icon_enabled: "$neutral00",
      button_danger_icon_focus: "$neutral00",
      button_danger_icon_hover: "$neutral00",
      button_danger_ring_focus: "#db30255c",
      button_danger_surface_active: "#d42c22",
      button_danger_surface_enabled: "$red50",
      button_danger_surface_focus: "$red50",
      button_danger_surface_hover: "#e45b52",
      button_danger_text_active: "$neutral00",
      button_danger_text_enabled: "$neutral00",
      button_danger_text_focus: "$neutral00",
      button_danger_text_hover: "$neutral00",
      button_default_icon_active: "$neutral80",
      button_default_icon_enabled: "$neutral80",
      button_default_icon_focus: "$neutral80",
      button_default_icon_hover: "$neutral80",
      button_default_ring_focus: "#106af25c",
      button_default_surface_active: "#cad4e0",
      button_default_surface_enabled: "$neutral20",
      button_default_surface_focus: "$neutral20",
      button_default_surface_hover: "#e1e8f1",
      button_default_text_active: "$neutral80",
      button_default_text_enabled: "$neutral80",
      button_default_text_focus: "$neutral80",
      button_default_text_hover: "$neutral80",
      button_group_default_seperator: "#35414e3d",
      button_group_disabled_seperator: "#35414e29",
      button_group_primary_seperator: "#e3edff3d",
      button_icon_disabled: "#35414e7a",
      button_primary_icon_active: "$neutral00",
      button_primary_icon_enabled: "$neutral00",
      button_primary_icon_focus: "$neutral00",
      button_primary_icon_hover: "$neutral00",
      button_primary_ring_focus: "#106af25c",
      button_primary_surface_active: "#0d60de",
      button_primary_surface_enabled: "$blue50",
      button_primary_surface_focus: "$blue50",
      button_primary_surface_hover: "#317ff4",
      button_primary_text_active: "$neutral00",
      button_primary_text_enabled: "$neutral00",
      button_primary_text_focus: "$neutral00",
      button_primary_text_hover: "$neutral00",
      button_subtle_icon_active: "$neutral70",
      button_subtle_icon_disabled: "#35414e7a",
      button_subtle_icon_enabled: "$neutral70",
      button_subtle_icon_focus: "$neutral70",
      button_subtle_icon_hover: "$neutral70",
      button_subtle_ring_focus: "#106af25c",
      button_subtle_surface_active: "#505e713d",
      button_subtle_surface_disabled: "#ffffff00",
      button_subtle_surface_enabled: "#ffffff00",
      button_subtle_surface_focus: "#ffffff00",
      button_subtle_surface_hover: "#505e7129",
      button_subtle_text_active: "$neutral70",
      button_subtle_text_disabled: "#35414e7a",
      button_subtle_text_enabled: "$neutral70",
      button_subtle_text_focus: "$neutral70",
      button_subtle_text_hover: "$neutral70",
      button_surface_disabled: "#d7e1eda3",
      button_text_disabled: "#35414e7a",
      button_warning_icon_active: "$neutral90",
      button_warning_icon_enabled: "$neutral90",
      button_warning_icon_focus: "$neutral90",
      button_warning_icon_hover: "$neutral90",
      button_warning_ring_focus: "#f0950c5c",
      button_warning_surface_active: "#e58c0b",
      button_warning_surface_enabled: "$yellow50",
      button_warning_surface_focus: "$yellow50",
      button_warning_surface_hover: "#f4ac3f",
      button_warning_text_active: "$neutral90",
      button_warning_text_enabled: "$neutral90",
      button_warning_text_focus: "$neutral90",
      button_warning_text_hover: "$neutral90",
      button_filter_empty_border_active: "#35414e3d",
      button_filter_empty_border_enabled: "#35414e3d",
      button_filter_empty_border_focus: "$blue50",
      button_filter_empty_border_hover: "#35414e3d",
      button_filter_empty_dismiss_icon: "#505e71b8",
      button_filter_empty_icon_active: "$neutral80",
      button_filter_empty_icon_enabled: "$neutral80",
      button_filter_empty_icon_focus: "$neutral80",
      button_filter_empty_icon_hover: "$neutral80",
      button_filter_empty_ring_focus: "$state_focus_ring_primary",
      button_filter_empty_surface_active: "#1f252c1f",
      button_filter_empty_surface_enabled: "#ffffff00",
      button_filter_empty_surface_focus: "#ffffff00",
      button_filter_empty_surface_hover: "#1f252c14",
      button_filter_empty_text_active: "$neutral80",
      button_filter_empty_text_enabled: "$neutral80",
      button_filter_empty_text_focus: "$neutral80",
      button_filter_empty_text_hover: "$neutral80",
      button_filter_selected_border_active: "#003da63d",
      button_filter_selected_border_enabled: "#003da63d",
      button_filter_selected_border_focus: "$blue50",
      button_filter_selected_border_hover: "#003da63d",
      button_filter_selected_icon_active: "$blue50",
      button_filter_selected_icon_enabled: "$blue50",
      button_filter_selected_icon_focus: "$blue50",
      button_filter_selected_icon_hover: "$blue50",
      button_filter_selected_ring_focus: "$state_focus_ring_primary",
      button_filter_selected_seperator_focus: "#003da63d",
      button_filter_selected_surface_active: "#aacaffb8",
      button_filter_selected_surface_enabled: "#aacaff5c",
      button_filter_selected_surface_focus: "#aacaff5c",
      button_filter_selected_surface_hover: "#aacaffa3",
      button_filter_selected_text_active: "$blue50",
      button_filter_selected_text_enabled: "$blue50",
      button_filter_selected_text_focus: "$blue50",
      button_filter_selected_text_hover: "$blue50",
      button_filter_seperator_focus: "$blue50",
      button_select_border: "#35414e3d",
      button_select_border_selected: "$blue50",
      button_select_surface_active: "$blue10",
      button_select_surface_disabled: "#d7e1eda3",
      button_select_surface_enabled: "$neutral00",
      button_select_surface_focus: "$neutral00",
      button_select_surface_hover: "$neutral00",
      button_select_surface_selected: "$blue10",
      button_select_text_active: "$blue50",
      button_select_text_disabled: "#35414e7a",
      button_select_text_enabled: "$neutral80",
      button_select_text_focus: "$neutral80",
      button_select_text_hover: "$blue50",
      button_select_text_selected: "$blue50",
      card_border: "$neutral20",
      card_border_selected: "$blue40",
      card_surface: "$neutral00",
      card_surface_active: "$neutral10",
      card_surface_hover: "$neutral05",
      card_surface_selected: "$blue05",
      card_surface_selected_active: "$neutral10",
      card_surface_selected_hover: "$blue10",
      checkbox_checked_ring_focus: "$state_focus_ring_primary",
      checkbox_checked_surface_active: "#0d60de",
      checkbox_checked_surface_enabled: "$blue50",
      checkbox_checked_surface_focus: "$blue50",
      checkbox_checked_surface_hover: "#317ff4",
      checkbox_icon_disabled: "#35414e7a",
      checkbox_icon_enabled: "$neutral00",
      checkbox_indeterminate_ring_focus: "$state_focus_ring_primary",
      checkbox_indeterminate_surface_active: "#0d60de",
      checkbox_indeterminate_surface_enabled: "$blue50",
      checkbox_indeterminate_surface_focus: "$blue50",
      checkbox_indeterminate_surface_hover: "#317ff4",
      checkbox_surface_disabled: "#a1adc43d",
      checkbox_unchecked_border_active: "$neutral50",
      checkbox_unchecked_border_enabled: "$neutral50",
      checkbox_unchecked_border_focus: "$blue50",
      checkbox_unchecked_border_hover: "$neutral50",
      checkbox_unchecked_ring_focus: "$state_focus_ring_primary",
      checkbox_unchecked_surface_active: "$neutral20",
      checkbox_unchecked_surface_enabled: "$neutral00",
      checkbox_unchecked_surface_focus: "#ffffff00",
      checkbox_unchecked_surface_hover: "$neutral10",
      chip_remove_icon_active: "#505e71",
      chip_remove_icon_enabled: "#a1adc4",
      chip_remove_icon_hover: "#7b8ba3",
      chip_surface: "#cad5e57a",
      chip_text: "$neutral70",
      code_snippet_border: "#505e711f",
      code_snippet_surface: "#505e7114",
      code_snippet_text: "$text_primary",
      counter_default_surface: "#cad5e5a3",
      counter_default_text: "$neutral70",
      counter_disabled_surface: "#d7e1eda3",
      counter_disabled_text: "#35414e7a",
      counter_primary_surface: "$blue50",
      counter_primary_text: "$neutral00",
      datepicker_day_surface_selected: "$blue50",
      datepicker_day_text_enabled: "$text_primary",
      datepicker_day_text_nextmonth_enabled: "$neutral60",
      datepicker_day_today_text_enabled: "$blue50",
      datepicker_day_today_text_selected: "$neutral00",
      datepicker_named_day: "$neutral60",
      datepicker_surface: "$surface_primary",
      dropdown_content_selected: "$blue50",
      dropdown_icon_active: "$text_primary",
      dropdown_icon_danger_active: "$red50",
      dropdown_icon_danger_enabled: "$red50",
      dropdown_icon_danger_focus: "$red50",
      dropdown_icon_danger_hover: "$red50",
      dropdown_icon_disabled: "#35414e7a",
      dropdown_icon_enabled: "$text_primary",
      dropdown_icon_focus: "$text_primary",
      dropdown_icon_hover: "$text_primary",
      dropdown_ring_focus: "$state_focus_ring_primary",
      dropdown_section_label: "$neutral60",
      dropdown_surface_active: "#505e713d",
      dropdown_surface_disabled: "$surface_transparent",
      dropdown_surface_enabled: "$surface_transparent",
      dropdown_surface_focus: "$surface_transparent",
      dropdown_surface_hover: "#505e7129",
      dropdown_surface_selected_active: "#aacaffb8",
      dropdown_surface_selected_enabled: "#aacaff5c",
      dropdown_surface_selected_hover: "#aacaffa3",
      dropdown_text_active: "$text_primary",
      dropdown_text_danger_active: "$red50",
      dropdown_text_danger_enabled: "$red50",
      dropdown_text_danger_focus: "$red50",
      dropdown_text_danger_hover: "$red50",
      dropdown_text_disabled: "#35414e7a",
      dropdown_text_enabled: "$text_primary",
      dropdown_text_focus: "$text_primary",
      dropdown_text_hover: "$text_primary",
      hint_count_text: "$neutral60",
      hint_icon_danger: "$red60",
      hint_icon_default: "$neutral60",
      hint_icon_success: "$green60",
      hint_icon_warning: "$yellow60",
      hint_text_danger: "$red60",
      hint_text_default: "$neutral60",
      hint_text_success: "$green60",
      hint_text_warning: "$yellow60",
      icon_blue: "$blue50",
      icon_control_active: "$neutral80",
      icon_control_disabled: "#505e713d",
      icon_control_enabled: "$neutral70",
      icon_control_hover: "$neutral60",
      icon_green: "$green50",
      icon_neutral: "$neutral70",
      icon_primary: "$neutral80",
      icon_purple: "$purple50",
      icon_red: "$red50",
      icon_secondary: "$text_secondary",
      icon_tertiary: "$text_tertiary",
      icon_yellow: "$yellow60",
      label_body_optional_text_disabled: "$neutral50",
      label_body_optional_text_enabled: "$neutral50",
      label_body_text_disabled: "$neutral50",
      label_body_text_enabled: "$neutral90",
      label_header_text_disabled: "$neutral50",
      label_header_text_enabled: "$neutral70",
      label_header_text_optional_disabled: "$neutral50",
      label_header_text_optional_enabled: "$neutral50",
      loader_spinner: "$blue50",
      loader_track: "#cad5e53d",
      logo_fill: "$neutral00",
      naivgation_item_text_hover: "$neutral00",
      navigation_item_icon_enabled: "$neutral50",
      navigation_item_icon_hover: "$neutral00",
      navigation_item_icon_selected: "$neutral00",
      navigation_item_text_enabled: "$neutral50",
      navigation_item_text_selected: "$neutral00",
      navigation_surface: "$neutral100",
      overlay_surface: "#1f272fa3",
      popover_surface: "$neutral00",
      radio_dot_disabled: "#35414e7a",
      radio_selected_dot_active: "$neutral00",
      radio_selected_dot_enabled: "$neutral00",
      radio_selected_dot_focus: "$neutral00",
      radio_selected_dot_hover: "$neutral00",
      radio_selected_ring_focus: "$state_focus_ring_primary",
      radio_selected_surface_active: "#0d60de",
      radio_selected_surface_enabled: "$blue50",
      radio_selected_surface_focus: "$blue50",
      radio_selected_surface_hover: "#317ff4",
      radio_surface_disabled: "#d7e1eda3",
      radio_unselected_border_active: "$neutral50",
      radio_unselected_border_enabled: "$neutral50",
      radio_unselected_border_focus: "$blue50",
      radio_unselected_border_hover: "$neutral50",
      radio_unselected_ring_focus: "$state_focus_ring_primary",
      radio_unselected_surface_active: "$neutral20",
      radio_unselected_surface_enabled: "$neutral00",
      radio_unselected_surface_focus: "$neutral00",
      radio_unselected_surface_hover: "$neutral10",
      scrollbar_handle_surface: "#505e713d",
      scrollbar_track_surface: "$neutral05",
      section_message_dismiss_icon: "$neutral70",
      section_message_icon_danger: "$red70",
      section_message_icon_default: "$neutral80",
      section_message_icon_success: "$green70",
      section_message_icon_warning: "$yellow70",
      section_message_surface_danger: "$red10",
      section_message_surface_default: "$neutral10",
      section_message_surface_success: "$green10",
      section_message_surface_warning: "$yellow10",
      section_message_text_danger: "$red70",
      section_message_text_default: "$neutral80",
      section_message_text_success: "$green70",
      section_message_text_warning: "$yellow70",
      state_focus_ring_primary: "#106af25c",
      stepper_icon_active: "$neutral50",
      stepper_icon_next: "$neutral50",
      stepper_icon_previous: "$neutral50",
      stepper_symbol_border_next: "$neutral50",
      stepper_symbol_surface_active: "$blue50",
      stepper_symbol_surface_next: "#ffffff00",
      stepper_symbol_surface_previous: "$neutral30",
      stepper_symbol_text_active: "$neutral00",
      stepper_symbol_text_next: "$neutral50",
      stepper_symbol_text_previous: "$neutral50",
      stepper_text_active: "$neutral80",
      stepper_text_next: "$neutral50",
      stepper_text_previous: "$neutral50",
      switch_off_knob_active: "$neutral50",
      switch_off_knob_disabled: "#35414e3d",
      switch_off_knob_enabled: "$neutral50",
      switch_off_knob_focus: "$neutral50",
      switch_off_knob_hover: "$neutral50",
      switch_off_ring_focus: "$state_focus_ring_primary",
      switch_off_track_border_active: "$neutral50",
      switch_off_track_border_enabled: "$neutral50",
      switch_off_track_border_focus: "$blue50",
      switch_off_track_border_hover: "$neutral50",
      switch_off_track_surface_active: "#505e713d",
      switch_off_track_surface_disabled: "#a1adc43d",
      switch_off_track_surface_enabled: "#ffffff00",
      switch_off_track_surface_focus: "#ffffff00",
      switch_off_track_surface_hover: "#505e7129",
      switch_on_knob_active: "$neutral00",
      switch_on_knob_disabled: "$neutral00",
      switch_on_knob_enabled: "$neutral00",
      switch_on_knob_focus: "$neutral00",
      switch_on_knob_hover: "$neutral00",
      switch_on_ring_focus: "$state_focus_ring_primary",
      switch_on_track_surface_active: "#0d60de",
      switch_on_track_surface_disabled: "#106af27a",
      switch_on_track_surface_enabled: "$blue50",
      switch_on_track_surface_focus: "$blue50",
      switch_on_track_surface_hover: "#317ff4",
      tab_ring_focus: "$state_focus_ring_primary",
      tab_surface_focus: "#ffffff00",
      tab_text_active: "#0d60de",
      tab_text_disabled: "#35414e7a",
      tab_text_enabled: "$neutral70",
      tab_text_focus: "$blue50",
      tab_text_hover: "#317ff4",
      tab_text_selected: "$blue50",
      tab_underline_selected: "$blue50",
      table_cell_border_selected: "$blue30",
      table_cell_surface_active: "#106af229",
      table_cell_surface_enabled: "$surface_transparent",
      table_cell_surface_hover: "#106af214",
      table_cell_surface_selected: "#106af214",
      table_header_text: "$neutral70",
      text_cursor: "$text_primary",
      text_input_danger_border: "$red50",
      text_input_default_border: "$neutral50",
      text_input_default_border_disabled: "$neutral20",
      text_input_default_border_focus: "$blue50",
      text_input_default_content: "$neutral90",
      text_input_default_content_disabled: "$neutral50",
      text_input_default_placeholder_disabled: "$neutral50",
      text_input_default_suggestion: "$neutral50",
      text_input_default_surface_disabled: "#d7e1ed7a",
      text_input_default_surface_enabled: "$neutral00",
      text_input_default_surface_focus: "$neutral00",
      text_input_default_surface_read_only: "#d7e1ed7a",
      text_input_icon_disabled: "$neutral40",
      text_input_icon_enabled: "$neutral60",
      text_input_success_border: "$green50",
      text_input_warning_border: "$yellow50",
      tooltip_surface: "$neutral90",
      tooltip_text: "$neutral00"
    },
    numbers: {}
  },
  Structure: {
    colors: {
      border_default: "$neutral20",
      separator_default: "$neutral20",
      surface_blue_subtle: "#aacaff3d",
      surface_green_subtle: "#71d78e3d",
      surface_neutral_subtle: "#a1adc43d",
      surface_primary: "$neutral00",
      surface_purple_subtle: "#b490ff3d",
      surface_red_subtle: "#fb7e763d",
      surface_secondary: "$neutral05",
      surface_transparent: "#ffffff00",
      surface_yellow_subtle: "#ffc8713d"
    },
    numbers: {
      space_4: "4px",
      space_8: "8px",
      space_12: "12px",
      space_16: "16px",
      space_24: "24px",
      space_32: "32px",
      space_48: "48px",
      space_64: "64px",
      radius_surface_4: "4px",
      space_h_subcomponent: "$space_4",
      space_v_subcomponent: "$space_4",
      radius_surface_8: "8px",
      space_h_component: "$space_8",
      space_v_component: "$space_12",
      margin_v_section: "$space_16",
      margin_h_section: "$space_24",
      space_h_group: "$space_24",
      margin_h_container: "$space_32",
      space_v_group: "$space_32"
    }
  },
  Typography: {
    colors: {
      text_danger: "$red60",
      text_link: "$blue50",
      text_primary: "$neutral90",
      text_secondary: "$neutral70",
      text_success: "$green60",
      text_tertiary: "$neutral50",
      text_warning: "$yellow60"
    },
    numbers: {}
  }
};

// stitches.config.ts
var stitchesConfig = {
  theme: {
    colors: __spreadProps(__spreadValues(__spreadValues(__spreadValues(__spreadValues({
      // Focus ring color
      primary_focus_ring: "rgba(16, 106, 242, 0.36)",
      // ❗ button_default_ring_focus
      danger_focus_ring: "rgba(219, 48, 37, 0.36)",
      // ❗ button_danger_ring_focus
      warning_focus_ring: "rgba(240, 149, 12, 0.36)",
      // ❗ button_warning_ring_focus
      success_focus_ring: "rgba(18, 161, 61, 0.36)",
      // ❔ unknown rgba color
      // Label colors
      label_primary_text_enabled: "$neutral100",
      label_primary_text_disabled: "$neutral50",
      label_secondary_text_enabled: "$neutral60",
      label_secondary_text_disabled: "$neutral50",
      // Input colors
      input_background_enabled: "$neutral00",
      input_background_hover: "$neutral05",
      // TODO: should be neutral0 plus Dark-alpha-0.08
      input_background_disabled: "$neutral10",
      input_background_readonly: "$neutral05",
      // TODO: need a readonly boxShadow
      input_default_border_enabled: "$neutral40",
      input_default_border_disabled: "$neutral50",
      // TODO: need this color
      input_default_border_active: "$blue50",
      input_danger_border_enabled: "$red50",
      input_warning_border_enabled: "$yellow50",
      input_success_border_enabled: "$green50",
      input_default_text_enabled: "$neutral100",
      input_default_text_disabled: "$neutral50",
      input_secondary_text_enabled: "$neutral40",
      // TODO: need a color for placeholder text in disabled state
      input_default_icon_active: "$blue50",
      input_default_icon_enabled: "$neutral60",
      input_default_caret_enabled: "$neutral100",
      input_default_icon_disabled: "$neutral50",
      input_primary_icon_readonly: "$neutral50",
      // Helper text colors
      helper_text_default: "$neutral60",
      helper_text_danger: "$red50",
      helper_text_warning: "$yellow50",
      helper_text_success: "$green50",
      helper_icon_default: "$neutral40",
      helper_icon_danger: "$red50",
      helper_icon_warning: "$yellow50",
      helper_icon_success: "$green50",
      // Loader colors
      loader_default_icon: "$blue50",
      // Checkbox colors
      checkbox_unchecked_background_enabled: "$neutral00",
      checkbox_unchecked_background_disabled: "$neutral10",
      checkbox_unchecked_background_hover: "$neutral05",
      // 💠 existing stitches config token(s)
      // checkbox_unchecked_border_enabled: '$neutral50',
      checkbox_unchecked_border_disabled: "$neutral40",
      // 💠 existing stitches config token(s)
      // checkbox_unchecked_border_focus: '$blue50',
      checkbox_checked_background_enabled: "$blue50",
      checkbox_checked_background_hover: "$blue50",
      checkbox_checked_background_disabled: "$neutral30",
      checkbox_default_icon_enabled: "$neutral00",
      checkbox_default_icon_disabled: "$neutral50",
      // Radio colors
      radio_unselected_background_enabled: "$neutral00",
      radio_unselected_background_disabled: "$neutral10",
      radio_unselected_background_hover: "$neutral05",
      // 💠 existing stitches config token(s)
      // radio_unselected_border_enabled: '$neutral50',
      // 💠 existing stitches config token(s)
      // radio_unselected_border_focus: '$blue50',
      radio_unselected_border_disabled: "$neutral40",
      radio_selected_background_disabled: "$neutral50",
      radio_selected_border_disabled: "$neutral30",
      radio_selected_border_active: "$blue50",
      radio_selected_border_hover: "$blue50",
      // Switch colors
      switch_selected_background_enabled: "$blue50",
      switch_selected_background_focus: "$blue50",
      switch_selected_background_disabled: "$blue20",
      switch_selected_thumb_enabled: "$neutral00",
      switch_unselected_background_enabled: "$neutral00",
      switch_unselected_background_focus: "$neutral00",
      switch_unselected_background_disabled: "$neutral20",
      switch_unselected_thumb_enabled: "$neutral40",
      switch_unselected_thumb_disabled: "$neutral30",
      switch_unselected_border_enabled: "$neutral40",
      switch_unselected_border_focus: "$blue50",
      // Chip colors
      // 💠 existing stitches config token(s)
      // chip_text: '$neutral70',
      // chip_surface: 'rgba(202, 213, 229, 0.48)', // ❗ chip_surface
      // chip_remove_icon_enabled: '$neutral50',
      // chip_remove_icon_hover: '$neutral60',
      // chip_remove_icon_active: '$neutral70',
      // Counter colors
      counter_default_text_enabled: "$neutral70",
      counter_default_text_disabled: "#B7BFC7",
      // ❔ unknown hex color
      counter_default_background_enabled: "rgba(202, 213, 229, 0.64)",
      // ❗ counter_default_surface
      counter_default_background_disabled: "#E9EAF0",
      // ❔ unknown hex color
      counter_strong_background_enabled: "$blue50",
      counter_strong_text_enabled: "$neutral00",
      // Dropdown colors
      dropdown_default_text_enabled: "$neutral100",
      dropdown_default_text_disabled: "$neutral40",
      dropdown_default_text_disabled_alpha: "rgba(53, 65, 78, 0.48)",
      // ❗ button_icon_disabled
      dropdown_danger_text_enabled: "$red50",
      dropdown_danger_text_disabled: "$red30",
      dropdown_action_text_enabled: "$blue50",
      dropdown_action_text_disabled: "$blue30",
      dropdown_default_sectiontext_enabled: "$neutral60",
      dropdown_default_background_enabled: "$neutral00",
      dropdown_default_background_hover: "$neutral05",
      dropdown_default_background_active: "$neutral05",
      dropdown_default_border_focus: "$blue30",
      dropdown_default_divider_enabled: "$neutral20",
      dropdown_default_icon_enabled: "$neutral100",
      dropdown_default_icon_disabled: "$neutral40",
      dropdown_danger_icon_enabled: "$red50",
      dropdown_danger_icon_disabled: "$red30",
      dropdown_action_icon_enabled: "$blue50",
      dropdown_action_icon_disabled: "$blue30",
      dropdown_default_track_enabled: "$neutral10",
      dropdown_default_handle_enabled: "$neutral40",
      // 💠 existing stitches config token(s)
      // dropdown_surface_selected_enabled: 'rgba(170, 202, 255, 0.36)', // ❗ button_filter_selected_surface_enabled
      // dropdown_surface_selected_hover: 'rgba(170, 202, 255, 0.64)', // ❗ button_filter_selected_surface_hover
      // dropdown_surface_selected_active: 'rgba(170, 202, 255, 0.72)', // ❗ button_filter_selected_surface_active
      // dropdown_ring_focus: 'rgba(16, 106, 242, 0.36)', // ❗ button_default_ring_focus
      // dropdown_content_selected: '$blue50',
      // Badge colors
      // 💠 existing stitches config token(s)
      // badge_surface_neutral: '#A1ADC43D',  // ❗ badge_surface_neutral
      // badge_text_neutral: '$neutral70',
      // badge_surface_blue: '#AACAFF3D', // ❗ badge_surface_blue
      // badge_text_blue: '$blue60',
      // badge_surface_green: '#71D78E3D', // ❗ badge_surface_green
      // badge_text_green: '$green60',
      // badge_surface_yellow: '#FFC8713D', // ❗ badge_surface_yellow
      // badge_text_yellow: '$yellow60',
      // badge_surface_red: '#FB7E763D', // ❗ badge_surface_red
      // badge_text_red: '$red60',
      // badge_surface_purple: '#B490FF3D', // ❗ badge_surface_purple
      // badge_text_purple: '$purple60',
      // Button colors
      button_primary_background_enabled: "$blue50",
      button_primary_background_hover: "#317FF4",
      // ❗ button_primary_surface_hover
      button_primary_background_active: "#0D60DE",
      // ❗ button_primary_surface_active
      button_primary_text: "$neutral00",
      button_primary_icon: "$neutral00",
      button_primary_border_focus: "rgba(16, 106, 242, 0.36)",
      // ❗ button_default_ring_focus
      button_default_background_enabled: "$neutral20",
      button_default_background_hover: "#E1E8F1",
      // ❗ button_default_surface_hover
      button_default_background_active: "#CAD4E0",
      // ❗ button_default_surface_active
      button_default_text: "$neutral80",
      button_default_icon: "$neutral80",
      button_default_border_focus: "rgba(16, 106, 242, 0.72)",
      // ❔ unknown rgba color
      button_warning_background_enabled: "$yellow50",
      button_warning_background_hover: "#F4AC3F",
      // ❗ button_warning_surface_hover
      button_warning_background_active: "#E58C0B",
      // ❗ button_warning_surface_active
      button_warning_text: "$neutral90",
      button_warning_icon: "$neutral90",
      button_warning_border_focus: "rgba(240, 149, 12, 0.36)",
      // ❗ button_warning_ring_focus
      button_danger_background_enabled: "$red50",
      button_danger_background_hover: "#E45B52",
      // ❗ button_danger_surface_hover
      button_danger_background_active: "#D42C22",
      // ❗ button_danger_surface_active
      button_danger_text: "$neutral00",
      button_danger_icon: "$neutral00",
      button_danger_border_focus: "rgba(219, 48, 37, 0.36)",
      // ❗ button_danger_ring_focus
      button_subtle_background_enabled: "$neutral00",
      button_subtle_background_hover: "rgba(80, 94, 113, 0.16)",
      // ❗ button_subtle_surface_hover
      button_subtle_background_active: "rgba(80, 94, 113, 0.24)",
      // ❗ button_subtle_surface_active
      button_subtle_text: "$neutral70",
      button_subtle_icon: "$neutral70",
      button_disabled_background: "rgba(215, 225, 237, 0.64)",
      // ❗ button_surface_disabled
      button_disabled_text: "rgba(53, 65, 78, 0.48)",
      // ❗ button_icon_disabled
      button_disabled_icon: "rgba(53, 65, 78, 0.48)",
      // ❗ button_icon_disabled
      button_disabled_subtle_background: "rgba(255, 255, 255, 0.0001)",
      button_disabled_subtle_text: "rgba(53, 65, 78, 0.48)",
      // ❗ button_icon_disabled
      button_disabled_subtle_icon: "rgba(53, 65, 78, 0.48)",
      // ❗ button_icon_disabled
      // Button Group colors
      button_group_primary_separator: "rgba(227, 237, 255, 0.24)",
      // ❗ button_group_primary_seperator
      button_group_default_separator: "rgba(53, 65, 78, 0.24)",
      // ❗ button_group_default_seperator
      button_group_disabled_separator: "rgba(53, 65, 78, 0.16)",
      // ❗ button_group_disabled_seperator
      // Filter Button colors
      filter_button_background: "$neutral00",
      filter_button_background_hover: "rgba(31, 37, 44, 0.08)",
      // ❗ button_filter_empty_surface_hover
      filter_button_background_active: "rgba(31, 37, 44, 0.12)",
      // ❗ button_filter_empty_surface_active
      filter_button_border: "2px solid transparent",
      filter_button_border_focus_color: "$blue40",
      filter_button_filters_selected_background: "rgba(170, 202, 255, 0.36)",
      // ❗ button_filter_selected_surface_enabled
      filter_button_filters_selected_background_hover: "rgba(170, 202, 255, 0.64)",
      // ❗ button_filter_selected_surface_hover
      filter_button_filters_selected_background_active: "rgba(170, 202, 255, 0.72)",
      // ❗ button_filter_selected_surface_active
      filter_button_filters_selected_background_focus: "rgba(170, 202, 255, 0.36)",
      // ❗ button_filter_selected_surface_enabled
      filter_button_filters_selected_color: "$blue50",
      filter_button_remove_border_color: "rgba(0, 61, 166, 0.24)",
      // button_filter_selected_border_active
      filter_button_remove_icon_color: "rgba(80, 94, 113, 0.72)",
      // ❗ button_filter_empty_dismiss_icon
      // Table colors
      table_row_background_hover: "rgb(16 106 242 / 8%)",
      // ❔ unknown rgba color
      table_row_background_hover_opaque: "rgb(239 243 253)",
      table_cell_background_active: "rgb(16 106 242 / 16%)",
      // ❗ table_cell_surface_active
      table_cell_background_active_opaque: "rgb(217 231 253)",
      // Scroll Container colors
      scroll_overflow_shadow_color: "rgb(15 19 23 / 16%)"
    }, tokens_default["Color Primitives"].colors), tokens_default["Color Semantic (System)"].colors), tokens_default["Structure"].colors), tokens_default["Typography"].colors), {
      // for backwards compatibility with existing tokens with different
      // shade naming/numbering convention
      neutral0: "$neutral00",
      neutral5: "$neutral05"
    }),
    space: __spreadValues({
      0: "0px",
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "24px",
      6: "32px",
      7: "48px",
      8: "64px",
      9: "96px",
      10: "128px"
    }, tokens_default["Structure"].numbers),
    fontSizes: {
      1: "12px",
      2: "14px",
      3: "16px",
      4: "20px",
      5: "24px",
      6: "32px"
    },
    fonts: {
      primary: "'Untitled Sans', sans-serif",
      code: "'Fira Code', monospace"
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      semibold: 700
    },
    lineHeights: {
      1: "16px",
      2: "20px",
      3: "24px",
      4: "32px",
      5: "40px"
    },
    letterSpacings: {
      1: "0px",
      2: "0.4px",
      3: "0.8px"
    },
    sizes: {
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "32px",
      8: "48px",
      9: "64px",
      10: "80px",
      11: "120px",
      12: "160px",
      13: "240px",
      14: "320px",
      15: "480px",
      16: "720px",
      17: "960px",
      18: "1200px"
    },
    borderWidths: {},
    borderStyles: {},
    // numeric sizing for radii to be deprecated in favor of t-shirt sizing
    radii: {
      none: "0px",
      1: "4px",
      2: "8px",
      round: "50%",
      // 'round' also to be deprecated
      "rounded-none": "0px",
      "rounded-sm": "2px",
      rounded: "4px",
      "rounded-lg": "8px",
      "rounded-xl": "12px",
      "rounded-full": "9999px"
    },
    shadows: {
      1: "0 1px 4px rgba(17, 17, 17, 20%)",
      2: "0 2px 8px rgba(17, 17, 17, 20%)",
      3: "0 4px 16px rgba(17, 17, 17, 20%)",
      4: "0 6px 24px rgba(17, 17, 17, 20%)",
      elevation1: "0px 1px 4px 0px rgba(15, 19, 23, 0.24)",
      elevation2: "0px 2px 8px rgba(15, 19, 23, 0.2)",
      elevation3: "0px 4px 16px rgba(15, 19, 23, 0.16)",
      primary_focus: "0 0 0 2px $colors$primary_focus_ring",
      danger_focus: "0 0 0 2px $colors$danger_focus_ring",
      warning_focus: "0 0 0 2px $colors$warning_focus_ring",
      success_focus: "0 0 0 2px $colors$success_focus_ring",
      scroll_overflow_x_shadow_left: "4px 0 4px -2px inset",
      scroll_overflow_x_shadow_right: "8px 0 4px -6px inset",
      scroll_overflow_y_shadow_top: "0 10px 6px -4px inset",
      scroll_overflow_y_shadow_bottom: "0 -10px 6px -6px inset",
      inset_border_top_1: "0 1px inset",
      inset_border_right_1: "-1px 0 inset",
      inset_border_bottom_1: "0 -1px inset",
      inset_border_left_1: "1px 0 inset"
    },
    zIndices: {
      1: "100",
      2: "200",
      3: "300",
      4: "400",
      max: "999"
    },
    transitions: {
      default: "200ms ease-in-out",
      infinite: "1s linear infinite",
      cubicCurve: "200ms cubic-bezier(0.65,0.05,0.36,1)",
      sineCurve: "200ms cubic-bezier(0.45,0.05,0.55,0.95)"
    },
    media: {
      sm: "(min-width: 834px)",
      md: "(min-width: 1024px)",
      lg: "(min-width: 1440px)",
      xl: "(min-width: 1680px)"
    }
  },
  utils: {
    p: (value) => ({
      padding: value
    }),
    pt: (value) => ({
      paddingTop: value
    }),
    pr: (value) => ({
      paddingRight: value
    }),
    pb: (value) => ({
      paddingBottom: value
    }),
    pl: (value) => ({
      paddingLeft: value
    }),
    px: (value) => ({
      paddingLeft: value,
      paddingRight: value
    }),
    py: (value) => ({
      paddingTop: value,
      paddingBottom: value
    }),
    m: (value) => ({
      margin: value
    }),
    mt: (value) => ({
      marginTop: value
    }),
    mr: (value) => ({
      marginRight: value
    }),
    mb: (value) => ({
      marginBottom: value
    }),
    ml: (value) => ({
      marginLeft: value
    }),
    mx: (value) => ({
      marginLeft: value,
      marginRight: value
    }),
    my: (value) => ({
      marginTop: value,
      marginBottom: value
    }),
    b: (value) => ({
      border: `1px solid ${value}`
    }),
    bt: (value) => ({
      borderTop: `1px solid ${value}`
    }),
    br: (value) => ({
      borderRight: `1px solid ${value}`
    }),
    bb: (value) => ({
      borderBottom: `1px solid ${value}`
    }),
    bl: (value) => ({
      borderLeft: `1px solid ${value}`
    }),
    bx: (value) => ({
      borderLeft: `1px solid ${value}`,
      borderRight: `1px solid ${value}`
    }),
    by: (value) => ({
      borderTop: `1px solid ${value}`,
      borderBottom: `1px solid ${value}`
    }),
    fd: (value) => ({
      flexDirection: value
    }),
    bg: (value) => ({
      background: value
    }),
    size: (value) => ({
      width: value,
      height: value
    }),
    truncate: (textOverflow) => ({
      textOverflow,
      whiteSpace: "nowrap",
      overflow: "hidden",
      overflowWrap: "break-word"
    }),
    reset: (value) => value === "*" ? {
      boxSizing: "border-box",
      "*, *::before, *::after": {
        boxSizing: "border-box"
      }
    } : {
      boxSizing: "border-box"
    }
  },
  themeMap: __spreadProps(__spreadValues({}, defaultThemeMap), {
    filter: "shadows"
  })
};
var { theme, styled, config, css, keyframes } = createStitches(stitchesConfig);

export {
  __spreadValues,
  __spreadProps,
  __restKey,
  __objRest,
  __async,
  theme,
  styled,
  config,
  css,
  keyframes
};
